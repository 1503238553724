import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '../ui/Button';

const Hero = () => (
  <section>
    <div className="container pt-2.5 pb-15 md:py-13.75">
      <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-between">
        <div className="mt-6 md:mt-0 mx-auto max-w-1.5xs md:mx-0 md:max-w-none text-center">
          <p className="text-left text-xs font-bold leading-fdm-7 text-primary">
            フードデリバリー最適化クラウド
          </p>
          <h1 className="mt-2.5 text-left text-fdm-2xl md:text-fdm-4xl font-bold leading-fdm-9 md:leading-fdm-11">
            <em className="block not-italic">飲食店のデリバリーを</em>
            <span className="text-primary">成功へ導く</span>
          </h1>
          <p className="mt-3 text-left text-sm leading-loose">
            売上やお客様の行動データを一括管理。
            <br />
            データ解析を行い、アカウントに合わせた
            <br className="md:hidden" />
            最適な改善提案を行います。
          </p>
          <div className="mt-10 mx-auto text-center max-w-xxs md:max-w-none">
            <Button
              href="/download"
              className="w-full md:w-50"
              color="primary"
              size="sm"
            >
              資料ダウンロード
            </Button>
            <Button
              href="/contact"
              className="w-full md:w-50 mt-3.75 md:mt-0 md:ml-5"
              size="sm"
            >
              お問い合わせ
            </Button>
          </div>
        </div>
        <div className="mx-auto md:mr-0 max-w-xs md:max-w-500 md:flex-1">
          <StaticImage
            src="../../images/img_fdm.png"
            alt=""
            className="w-full"
          />
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
