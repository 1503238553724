import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Query } from 'types/gatsby-graphql';

const Infomation = () => {
  const infoData: Query = useStaticQuery(graphql`
    query LatestInfoQuery {
      allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "FDMリリース" } } } }
        }
        sort: { fields: date, order: DESC }
        limit: 1
      ) {
        edges {
          node {
            id
            title
            date
            categories {
              nodes {
                name
                link
              }
            }
          }
        }
      }
    }
  `);
  return (
    <section className="bg-primary">
      <div className="container py-2 md:py-2 flex items-center md:justify-center">
        <div className="text-xs text-accent font-semibold uppercase">New</div>
        <a
          href={`https://lisatech.jp/news/${infoData.allWpPost.edges[0].node.id}`}
          target="_blank" rel="noopener noreferrer"
        >
          <p className="ml-2 flex-1 md:flex-none text-xs text-white leading-normal">
            {infoData.allWpPost.edges[0].node.title}
          </p>
        </a>
      </div>
    </section>
  );
};

export default Infomation;
