import * as React from 'react';
import { Button } from '../ui/Button';

const CTA = () => (
  <section className="bg-pale">
    <div className="container py-15 lg:py-20">
      <h2 className="text-center font-medium text-lg tracking-wide">
        お気軽にお問い合わせください
      </h2>
      <p className="mt-6 md:mt-10 flex justify-center text-sm leading-loose">
        フードデリバリーマネージャーでは、ご導入の相談を無料で承っております。
        <br />
        貴社の課題を伺った上で、専門スタッフがさらに詳しくご説明いたします。{' '}
        <br />
        ご不明な点はお気軽にお問い合わせください。
      </p>
      <div className="mt-12 mx-auto text-center max-w-xxs md:max-w-none">
        <Button href="/download" className="w-full md:w-75" color="primary">
          資料ダウンロード
        </Button>
        <Button
          href="/contact"
          className="w-full md:w-75 mt-3.75 md:mt-0 md:ml-5"
        >
          お見積り・お問い合わせ
        </Button>
      </div>
    </div>
  </section>
);

export default CTA;
