import * as React from 'react';
import classNames from 'classnames';
import { Button } from '../../ui/Button';

type SectionCTAPropsType = {
  className?: string;
  text: string;
}

const SectionCTA: React.VFC<SectionCTAPropsType> = ({className, text}) => (
  <div className={classNames('text-center', className)}>
    <p className="text-xs">{text}</p>
    <Button href="/download" className="w-full md:w-75 mt-5 mx-auto max-w-xxs md:max-w-sm" color="primary">資料ダウンロード</Button>
  </div>
);

export default SectionCTA;