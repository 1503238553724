import * as React from 'react';
import classNames from 'classnames';

type SectionTitlePropsType = {
  className?: string;
  children: React.ReactNode;
}

const SectionTitle: React.VFC<SectionTitlePropsType> = ({className, children}) => (
  <h2 className={classNames('text-center font-medium text-fdm-xl md:text-fdm-3xl tracking-fdm-widest md:tracking-normal leading-fdm-9 md:leading-fdm-10', className)}>
    {children}
  </h2>
);

export default SectionTitle;