import * as React from 'react';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CaseType } from '../../types/Case';
import ArrowLeftIcon from '../../images/icon_case_arrow_left.svg';
import ArrowRightIcon from '../../images/icon_case_arrow_right.svg';
import CloseIcon from '../../images/icon_close.svg';

type CaseModalCardPropsType = {
  isOpen: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  onClick: VoidFunction;
  prevBtnAction: VoidFunction;
  nextBtnAction: VoidFunction;
  currentDataIndex: number;
  caseListLength: number;
  data: CaseType
}

const CaseModal: React.VFC<CaseModalCardPropsType> = ({
  isOpen,
  onClose,
  onClick,
  prevBtnAction,
  nextBtnAction,
  currentDataIndex,
  caseListLength,
  data
}) => (
  <Transition.Root show={isOpen} as={Fragment}>
    <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" onClose={onClose}>
      <div id="case-dialog" className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="relative inline-block align-middle bg-white rounded-lg p-7.5 md:p-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 md:max-w-3xl text-gray">
            <div>
              <div className="md:flex flex-row-reverse break-all">
                <div className="md:ml-10 flex-1">
                  <Dialog.Title as="h3" className="font-medium text-lg tracking-wide">
                    {data.heading}
                  </Dialog.Title>
                  <div className="mt-6">
                    <p className="text-sm leading-loose">
                      {data.body}
                    </p>
                  </div>
                </div>
                <div className="mt-9 md:mt-0 md:w-60">
                  <div className="relative rounded-fdm-10 overflow-hidden z-10">
                    {data.image}
                  </div>
                  <div className="mt-4">
                    <p className="text-sm leading-loose">{data.companyName}{!data.sign && ' 様'}</p>
                    {data.sign && (
                      <div className="mt-4 text-xs leading-relaxed">
                        {data.sign}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <ul className="mt-10 flex justify-center items-center text-sm space-x-8">
              {currentDataIndex > 0 && (
                <li>
                  <button type="button" onClick={prevBtnAction} className="flex items-center outline-none hover:cursor-pointer hover:opacity-60 transition-opacity duration-300"><ArrowLeftIcon /><span className="ml-2">前の事例</span></button>
                </li>
              )}
              {currentDataIndex !== caseListLength -1 && (
                <li>
                  <button type="button" onClick={nextBtnAction} className="flex items-center outline-none hover:cursor-pointer hover:opacity-60 transition-opacity duration-300"><span className="mr-2">次の事例</span><ArrowRightIcon /></button>
                </li>
              )}
            </ul>
            <button onClick={onClick} type="button" className="outline-none absolute top-4 right-4"><span className="sr-only">閉じる</span><CloseIcon /></button>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

export default CaseModal;
