import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import ArrowBottomIcon from '../../images/icon_arrow_bottom.svg';
import ArrowRighfIcon from '../../images/icon_arrow_right.svg';
import ArrowTopIcon from '../../images/icon_arrow_top.svg';
import SectionCTA from './common/SectionCTA';
import SectionTitle from './common/SectionTitle';
import SectionDescription from './common/SectionDescription';

const FlowList = [
  {
    title: <>ビッグデータから<br />市場調査</>,
    description: '店舗周辺の調査を行い、 エリアの特性に沿った 商品やページ設計を行う',
    image: <StaticImage src="../../images/step/img_step01.png" alt="" />,
  },
  {
    title: <>導入後2ヶ月間<br />専任担当者が伴走</>,
    description: '専任担当者がシステム利用方法や業態などを、隔週でアドバイス',
    image: <StaticImage src="../../images/step/img_step02.png" alt="" />,
  },
  {
    title: <>運用データを解析<br />改善策のご提案</>,
    description: 'FDMが課題を発見して、売上向上の土台を固める改善策をご提案',
    image: <StaticImage src="../../images/step/img_step03.png" alt="" />,
  },
  {
    title: <>お客様の手による<br />改善案の実行</>,
    description: '届いた改善案を実行し、アカウントページに反映 FDMが効果検証を図る',
    image: <StaticImage src="../../images/step/img_step04.png" alt="" />,
  },
  {
    title: <>月に一回<br />定期ミーティング</>,
    description: '専任担当者と今後の対策や方向性をすり合わせ、運用を軌道に乗せる',
    image: <StaticImage src="../../images/step/img_step05.png" alt="" />,
  },
];

type FlowItemPropsType = {
  data: {
    title: JSX.Element;
    description: string;
    image: JSX.Element;
  };
  num: number;
}

const FlowItem: React.VFC<FlowItemPropsType> = ({data, num}) => (
  <div className="flex md:block">
    <div className="relative">
      <div className="absolute z-10 top-0 left-0 flex items-center justify-center w-6 h-6 border border-gray bg-white rounded-full font-semibold font-en">{num}</div>
      <div className="rounded-fdm-10 overflow-hidden w-32 md:w-full">{data.image}</div>
    </div>
    <dl className="flex-1 ml-7 md:ml-0 md:mt-2">
      <dt className="text-lg leading-fdm-7">{data.title}</dt>
      <dd className="mt-4 text-sm tracking-fdm-wider leading-loose">{data.description}</dd>
    </dl>
  </div>
)

const Flow = () => (
  <section id="flow">
    <div className="container py-15 lg:py-20">
      <SectionTitle>ご利用の流れ</SectionTitle>
      <SectionDescription className="mt-10">
        サポートチームと定期的にミーティングを行い、<br className="md:hidden" />アカウントを常に最適の状態にいたします。
      </SectionDescription>
      <ul className="md:flex mt-9 md:mt-20 space-y-16 md:space-y-0 md:space-x-10">
        {FlowList.map((item, index) => (
          <li key={item.description} className="relative flex-1">
            <FlowItem  num={index + 1} data={item}/>
            {index > 0 && (
              <>
                <div className="md:hidden absolute -top-10 left-56">
                  <ArrowBottomIcon />
                </div>
                <div className="hidden md:block absolute top-20 -left-8">
                  <ArrowRighfIcon />
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
      <div className="mt-10 md:w-1/2 md:ml-auto">
        <p className="relative text-sm tracking-wide md:py-2 md:pb-4 md:px-6 md:inline-block md:border-b-3 md:border-l-3 md:border-r-3 border-gray-light md:text-xs">
          ③〜⑤を継続的に行うことで、アカウントを常に最適な状態で運用
          <span className="hidden md:block absolute -top-1 -left-1.75">
            <ArrowTopIcon />
          </span>
        </p>
      </div>
      <SectionCTA className="mt-10 md:mt-15" text="導入後の流れや効果について詳しくご紹介" />
    </div>
  </section>
);

export default Flow;