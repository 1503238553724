import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import SectionCTA from './common/SectionCTA';
import SectionTitle from './common/SectionTitle';

const FeatureList = [
  {
    key: '01',
    title: <>データの「見える化」で<br />アカウントの健康状態を把握する</>,
    description: <>UberEats等のデリバリープラットフォームの各数値を一括管理することで、店舗・アカウントの現状を正確に把握することが可能です。<span className="relative block mt-6 pl-4 text-xs leading-relaxed"><span className="absolute top-0 left-0">※</span>UberEatsはUber Technologies社の商標登録、または商標です</span></>,
    image: <StaticImage src="../../images/function/img_fun01.png" alt="" className="w-full" />
  },
  {
    key: '02',
    title: <>データ分析から<br />最適なアカウントをつくる</>,
    description: '各データを弊社専任コンサルタントが分析します。その結果と今まで培ってきた知見をもとに、最適な改善策が自動で提案されます。',
    image: <StaticImage src="../../images/function/img_fun02.png" alt="" className="w-full" />
  },
  {
    key: '03',
    title: <>業態やエリアに適した<br />マーケティング支援</>,
    description: '各データの分析結果から、各アカウント・エリア・業態毎に最適なマーケティング施策が自動で提案され、広告対効果が改善されます。',
    image: <StaticImage src="../../images/function/img_fun03.png" alt="" className="w-full" />
  },
  {
    key: '04',
    title: 'エリアの可視化でニーズに沿ったページ設計や商品開発が可能',
    description: '店舗周辺のUberEats等の導入店の調査を行い、人気業態及び競合店舗を調査することで最適なページ設計に役立てます。',
    image: <StaticImage src="../../images/function/img_fun04.png" alt="" className="w-full" />
  },
  {
    key: '05',
    title: <>フードデリバリーの運用で<br />出てきた課題を徹底サポート</>,
    description: '飲食店のデリバリー成功のため徹底したサポートを行っております。また、UberEats等の開業サポートも行っております。',
    image: <StaticImage src="../../images/function/img_fun05.png" alt="" className="w-full" />
  },
];

type FeatureItemPropsType = {
  data: {
    key: string;
    title: JSX.Element | string;
    description: JSX.Element | string;
    image: JSX.Element;
  };
};

const FeatureItem: React.VFC<FeatureItemPropsType> = ({data}) => (
  <>
    <div className="relative rounded-fdm-10 overflow-hidden z-10">
      {data.image}
    </div>
    <dl className="mt-6">
      <dt className="text-lg tracking-fdm-wider leading-fdm-7">{data.title}</dt>
      <dd className="mt-4 text-sm tracking-fdm-wider leading-loose">{data.description}</dd>
    </dl>
  </>
)

const Feature = () => (
  <section id="feature">
    <div className="container py-15 lg:py-20">
      <SectionTitle>特長と機能</SectionTitle>
      <ul className="mt-10 md:mx-auto md:mt-20 md:flex flex-wrap justify-center space-y-16 md:space-y-0 md:max-w-3xl lg:max-w-none">
        {FeatureList.map((item, i) => (
          <li key={item.key} className={classNames('md:w-52 lg:w-70 md:mb-16', (i+1) % 3 !== 1 ? 'md:ml-12 lg:ml-15' : '')}>
            <FeatureItem data={item}/>
          </li>
        ))}
      </ul>
      <SectionCTA className="mt-16 md:mt-20" text="標準機能やオプション機能を詳しくご紹介" />
    </div>
  </section>
);

export default Feature;