import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// const slideshowListStyles = {
//   width: 1610;
//   animation: loop-slide 16s infinite linear 2s both;
// }

const CustomerLogoList = [
  {
    key: '01',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo01.png" height={70} width={120} alt=""/>,
    alt: ''
  },
  {
    key: '02',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo02.png" height={70} width={110} alt=""/>,
    alt: ''
  },
  {
    key: '03',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo03.png" height={70} width={61} alt=""/>,
    alt: ''
  },
  {
    key: '04',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo04.png" height={70} width={135} alt=""/>,
    alt: ''
  },
  {
    key: '05',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo05.png" height={70} width={92} alt=""/>,
    alt: ''
  },
  {
    key: '06',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo06.png" height={70} width={114} alt=""/>,
    alt: ''
  },
  {
    key: '07',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo07.png" height={70} width={46} alt=""/>,
    alt: ''
  },
  {
    key: '08',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo08.png" height={70} width={112} alt=""/>,
    alt: ''
  },
  {
    key: '09',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo09.png" height={70} width={116} alt=""/>,
    alt: ''
  },
  {
    key: '10',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo10.png" height={70} width={98} alt=""/>,
    alt: ''
  },
  {
    key: '11',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo11.png" height={70} width={76} alt=""/>,
    alt: ''
  },
  {
    key: '12',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo12.png" height={70} width={131} alt=""/>,
    alt: ''
  },
  {
    key: '13',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo13.png" height={70} width={102} alt=""/>,
    alt: ''
  },
  {
    key: '14',
    imageSP: <StaticImage src="../../images/customer_logo/img_logo14.png" height={70} width={37} alt=""/>,
    alt: ''
  },
]

const LogoClouds = () => (
    <section className="py-5 md:py-6 bg-white border-t border-gray-lightest overflow-hidden">
      <div className="slideshow space-x-5 flex transform scale-85 md:scale-100">
        <ul className="slideshow__list inline-block space-x-5">
          {CustomerLogoList.map((item) => (
            <div key={item.key} className="inline-block">
              {item.imageSP}
            </div>
          ))}
        </ul>
        <ul className="slideshow__list inline-block space-x-5">
          {CustomerLogoList.map((item) => (
            <div key={item.key} className="inline-block">
              {item.imageSP}
            </div>
          ))}
        </ul>
        <ul className="slideshow__list inline-block space-x-5">
          {CustomerLogoList.map((item) => (
            <div key={item.key} className="inline-block">
              {item.imageSP}
            </div>
          ))}
        </ul>
      </div>
    </section>
  );

export default LogoClouds;