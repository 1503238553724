import * as React from 'react';
import { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CaseType } from '../../types/Case';
import SectionCTA from './common/SectionCTA';
import SectionTitle from './common/SectionTitle';
import SectionDescription from './common/SectionDescription';
import CaseModal from './CaseModal';

const caseList = [
  {
    key: 'ケース01',
    title: (
      <>
        数値の一元管理だけでなく、
        <br />
        数値に基づいた改善提案も
        <br />
        大きな魅力です。
      </>
    ),
    companyName: '株式会社 トランジットジェネラルオフィス',
    image: <StaticImage src="../../images/case/img_case02.jpg" alt="" />,
    heading: (
      <>
        数値の一元管理だけでなく、
        <br />
        数値に基づいた改善提案も
        <br />
        大きな魅力です。
      </>
    ),
    body: (
      <>
        デリバリーに関する数値の一元管理ができ、最適な改善提案まで自動で提案していただけるので非常に助かっています。これまでは、自分たちでエクセルを用いて各店の数字を手作業で集計し、分析して、店舗へのフィードバック等を行っておりました。FDMにはこれらを全て自動化させ、複数の店舗の各種数値を一元管理する機能があり、作業工数も削減され非常に助かっております。
        <br />
        また、数字が見られるだけではなく、数値に基づいた改善提案をいただけるのも大きな魅力です。自分たちだけでは気づかないような改善提案もあり、フードデリバリーを成長させるにあたり自社だけで取り組むよりも効率的に運営ができるようになったと感じております。
      </>
    ),
    sign: (
      <>
        オペレーション事業本部
        <br />
        コンサルティング＆サポート部
        <br />
        セールス＆マーケティングセクション
        <br />
        マネージャー
        <br />
        佐々木 皓基 様
      </>
    ),
  },
  {
    key: 'ケース02',
    title: (
      <>
        市場調査による提案を実践。
        <br />
        売上が3倍以上にまで向上した
        <br />
        店舗を作れました。
      </>
    ),
    companyName: '株式会社 酒屋',
    image: <StaticImage src="../../images/case/img_case03.jpg" alt="" />,
    heading: (
      <>
        市場調査による提案を実践。
        <br />
        売上が3倍以上にまで向上した
        <br />
        店舗を作れました。
      </>
    ),
    body: (
      <>
        ゴーストレストランを含め5店舗出店したが売上が伸びず、何をどうやって改善したらいいのかわからずでした。
        <br />
        そんな折にFDMさんと契約させていただきました。市場調査をして売れるであろう業態の提案と、既にヒットしている業態を教えていただいて、そのまま実践したところ、デリバリー売上が3倍以上になった店舗を作れました。
        <br />
        今後とも、売上が伸び悩んでいる他業態の改善策の提案と新業態の提案を引き続きお願いし、デリバリー売上で1店舗300万の月商を目標に、お力添えをお願いしたいです。
      </>
    ),
    sign: (
      <ul className="space-y-2.5">
        <li>
          営業部長
          <br />
          高澤 直樹 様
        </li>
        <li>
          経理/財務
          <br />
          特命課長 渡邉 雄介 様
        </li>
      </ul>
    ),
  },
  {
    key: 'ケース03',
    title: (
      <>
        ネットの知識が乏しい私でも、
        <br />
        1日5千円しかなかった売上が
        <br />
        3万円になりました。
      </>
    ),
    companyName: '餃子満彩',
    image: <StaticImage src="../../images/case/img_case04.jpg" alt="" />,
    heading: (
      <>
        ネットの知識が乏しい私でも、
        <br />
        1日5千円しかなかった売上が
        <br />
        3万円になりました。
      </>
    ),
    body: (
      <>
        UberEatsの売上は1日5千円程度でした。もともとネット関係の知識が全然なくて、何をしたら改善されるのかも分からなく、困っていたんです。
        <br />
        それからフードデリバリーマネージャーを取り組んでいったら、平均して1日1.5万円ぐらいの売上になりました。
        <br />
        1日の売上が3万円を越える日もあり、これからも続けていきたいと思っています。
      </>
    ),
    sign: <>吉田 佳世 様</>,
  },
  {
    key: 'ケース04',
    title: 'わからないことは何でも相談。しっかり結果を出すことができました。',
    companyName: 'マルダイテック ホルモン豚屋',
    image: <StaticImage src="../../images/case/img_case05.jpg" alt="" />,
    heading: (
      <>
        わからないことは何でも相談。
        <br />
        しっかり結果を出すことができました。
      </>
    ),
    body: (
      <>
        Facebookでフードデリバリーマネージャーのページを見かけて、サービス内容を伺いました。
        当時コロナウィルス感染拡大により、デリバリー事業に力を入れようと思っていたタイミングでしたので、非常に魅力的なサービスと感じ申し込みをさせていただきました！
        <br />
        メニュー構成なども相談することができて非常にありがたかったです。そしてしっかり結果が出て、大変満足しております。ありがとうございました。
      </>
    ),
    sign: <>木村 孔明 様</>,
  },
];

type CaseItemPropsType = {
  data: CaseType;
  onClick: VoidFunction;
};

const CaseItem: React.VFC<CaseItemPropsType> = ({ data, onClick }) => (
  <button
    type="button"
    className="relative h-full flex flex-col shadow-sm overflow-hidden z-10 rounded-xl hover:cursor-pointer hover:opacity-60 duration-300 max-w-xs mx-auto"
    onClick={onClick}
  >
    <div className="">{data.image}</div>
    <div className="flex-1 flex flex-col w-full pt-5 pb-6 px-5 bg-white text-left break-all">
      <h3 className="flex-1 text-lg tracking-fdm-wider leading-fdm-7">
        {data.title}
      </h3>
      <p className="mt-6 text-sm leading-fdm-5">{data.companyName} 様</p>
    </div>
  </button>
);

const Case = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState<CaseType>(
    caseList[0]
  );
  const [currentDataIndex, setCurrentDataIndex] = useState(0);

  const openModal = (data: CaseType, i: number) => {
    setCurrentModalData(data);
    setIsModalOpen(true);
    setCurrentDataIndex(i);
  };

  const setCaseModalContents = (mode: 'prev' | 'next', i: number) => {
    const index = mode === 'prev' ? i - 1 : i + 1;
    setCurrentModalData(caseList[index]);
    setCurrentDataIndex(index);
  };

  return (
    <section className="bg-pale overflow-hidden" id="case">
      <div className="container py-15 lg:py-20">
        <SectionTitle>実際の効果は？</SectionTitle>
        <SectionDescription className="mt-10">
          フードデリバリーマネージャー（FDM）
          <br className="md:hidden" />
          <span className="hidden md:inline"> </span>お客様の声
        </SectionDescription>
        <div className="mt-10 mx-auto max-w-sm md:max-w-none">
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            breakpoints={{
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
            }}
          >
            {caseList.map((item, i) => (
              <SwiperSlide key={item.key}>
                <div className="h-full text-center">
                  <CaseItem data={item} onClick={() => openModal(item, i)} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <SectionCTA
          className="mt-10 md:mt-15"
          text="導入事例や効果を詳しくご紹介"
        />
      </div>
      <CaseModal
        data={currentModalData}
        currentDataIndex={currentDataIndex}
        caseListLength={caseList.length}
        isOpen={isModalOpen}
        onClose={setIsModalOpen}
        onClick={() => setIsModalOpen(false)}
        prevBtnAction={() => setCaseModalContents('prev', currentDataIndex)}
        nextBtnAction={() => setCaseModalContents('next', currentDataIndex)}
      />
    </section>
  );
};

export default Case;
