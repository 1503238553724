import * as React from 'react';
import Layout from '../components/common/Layout';
import Infomation from '../components/lp/Infomation';
import LogoClouds from '../components/lp/LogoClouds';
import Hero from '../components/lp/Hero';
import About from '../components/lp/About';
import Feature from '../components/lp/Feature';
import Case from '../components/lp/Case';
import Flow from '../components/lp/Flow';
import News from '../components/lp/News';
import CTA from '../components/lp/CTA';

// markup
const IndexPage = () => (
    <Layout pageTitle="FDM フードデリバリーマネージャー">
      <Infomation />
      <Hero />
      <LogoClouds />
      <About />
      <Feature />
      <Case />
      <Flow />
      <News />
      <CTA />
    </Layout>
  );

export default IndexPage;
