import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SectionCTA from './common/SectionCTA';
import SectionTitle from './common/SectionTitle';

const About = () => (
  <section className="bg-pale" id="about">
    <div className="container py-15 lg:py-20">
      <SectionTitle>課題が可視化され、<br className="md:hidden" />改善案が届く</SectionTitle>
      <div className="mt-10 md:mt-20 md:flex items-start">
        <div className="rounded-fdm-10 overflow-hidden z-10 flex-1">
          <StaticImage src="../../images/img_about.jpg" alt="" />
        </div>
        <dl className="mt-10 md:mt-0 md:ml-20 md:w-95">
          <dt className="text-lg font-medium tracking-fdm-widest leading-fdm-7">
            お店の現状把握から<br />課題解決まで徹底サポート
          </dt>
          <dd className="mt-5 text-sm tracking-fdm-wider leading-loose">
            お店のデリバリーアカウントを分析し、そのアカウントに沿った最適な改善提案を行うプラットフォームです。独自のシステムでデータ分析を行い、課題を可視化します。
            <br />
            分析結果をもとに、注文確定率やリピート率の向上や使用する写真の品質、商品構成や価格設定、出店地域の販売戦略、マーケティング戦略に至るまで、様々な改善案を自動で受け取ることができます。
            <br />
            お店はその改善内容を実行するだけで、アカウントを最適化することが可能になります。
          </dd>
        </dl>
      </div>
      <SectionCTA className="mt-16 md:mt-20" text="サービス内容や導入方法を詳しくご紹介" />
    </div>
  </section>
);

export default About;