import * as React from 'react';
import classNames from 'classnames';

type SectionDescriptionPropsType = {
  className?: string;
  children: React.ReactNode;
}

const SectionDescription: React.VFC<SectionDescriptionPropsType> = ({className, children}) => (
  <p className={classNames('text-sm text-center leading-loose tracking-wide', className)}>
    {children}
  </p>
);

export default SectionDescription;